"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var hooks_1 = require("./store/hooks");
var theme_slice_1 = require("@clearcourse/merlin-pkg-components/lib/features/theme.slice");
var Merlin_js_1 = require("@clearcourse/merlin-pkg-components/lib/Merlin.js");
var App = function () {
    var theme = (0, hooks_1.useAppSelector)(function (state) { return state.theme.theme; });
    var dispatch = (0, hooks_1.useAppDispatch)();
    (0, react_1.useEffect)(function () {
        setTimeout(function () {
            dispatch((0, theme_slice_1.changeTheme)('theme-alternate'));
        }, 5000);
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: theme }, { children: [(0, jsx_runtime_1.jsx)("h1", { children: "app" }), (0, jsx_runtime_1.jsx)(Merlin_js_1.Login, {})] })));
};
exports["default"] = App;
