"use strict";
exports.__esModule = true;
exports.changeTheme = exports.initialState = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var changeThemeReducer_1 = require("./reducers/changeThemeReducer");
exports.initialState = {
    theme: 'theme-default'
};
var themeSlice = (0, toolkit_1.createSlice)({
    name: 'theme',
    initialState: exports.initialState,
    reducers: {
        changeTheme: changeThemeReducer_1.changeThemeReducer
    }
});
exports.changeTheme = themeSlice.actions.changeTheme;
exports["default"] = themeSlice.reducer;
